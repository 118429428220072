import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getAuth, connectAuthEmulator, initializeAuth, indexedDBLocalPersistence, Auth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
// import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Capacitor } from '@capacitor/core'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const storage = getStorage(firebaseApp)

export const auth = whichAuth()

function whichAuth(): Auth {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    })
  } else {
    return getAuth()
  }
}

if (process.env.NEXT_PUBLIC_USE_EMULATORS === 'true') {
  try {
    // await FirebaseAuthentication.useEmulator({ host: 'localhost', port: 9099 })
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
    connectFunctionsEmulator(functions, 'localhost', 5001)
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectStorageEmulator(storage, 'localhost', 9199)
  } catch {
    // this code throws if it tries to run more than once
  }
}
