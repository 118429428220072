'use client'

import Bugsnag from './bugsnag'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { auth, db, functions } from '~/lib/firebase'
import { User } from '~/types'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics'
import { Capacitor } from '@capacitor/core'

type UserState = {
  user?: User
  isLoading: boolean
}

export const UserContext = createContext<UserState | undefined>(undefined)

type Props = { defaultUser?: User; defaultIsLoading?: boolean; children: ReactNode }

export default function UserProvider({ defaultUser = undefined, defaultIsLoading = true, children }: Props) {
  const [user, setUser] = useState<User | undefined>(defaultUser)
  const [isLoading, setIsLoading] = useState<boolean>(defaultIsLoading)

  useEffect(() => {
    // Listen for an authenticated user
    const unsubscriber = auth.onAuthStateChanged(async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const userFromStoreRef = doc(db, `users/${firebaseUser.uid}`)
          const userDoc = await getDoc(userFromStoreRef)
          let userFromStore = userDoc.data() as User | undefined

          if (!userFromStore) {
            // if we don't have a new user, that means a new user just logged in for the first time
            // call the server function to create a new user
            console.log('creating a new user')
            const createUser = httpsCallable(functions, 'users-create_user')
            userFromStore = (await createUser()).data as User

            console.log('user created')
          }

          if (!userFromStore) throw new Error(`logged in user is mssing a user record in firestore ${firebaseUser.uid}`)

          // set the user for GA
          await FirebaseAnalytics.setUserId({ userId: userFromStore.id })

          // set the user for Crashlytics
          if (Capacitor.isNativePlatform()) await FirebaseCrashlytics.setUserId({ userId: userFromStore.id })

          // initialize Bugsnag so exceptions report the current user
          Bugsnag.setUser(userFromStore.id /*, user.email, user.displayName*/)

          console.log('userFromStore', userFromStore)
          console.log('setting user from server')
          setUser(userFromStore)
        } else {
          console.log('setting user undefined')
          setUser(undefined)
        }
      } catch (error: any) {
        console.log(error)
        Bugsnag.notify(error)
      } finally {
        console.log('setIsLoading(false)')
        setIsLoading(false)
      }
    })

    // Unsubscribe auth listener on unmount
    return (): void => unsubscriber()
  }, [])

  return <UserContext.Provider value={{ user, isLoading }}>{children}</UserContext.Provider>
}

export const useUserState = (): UserState => {
  const userState = useContext(UserContext)
  if (!userState) throw new Error("Can't call useUser before the user context is loaded")
  return userState
}

export const useUser = (): User => {
  const userState = useContext(UserContext)
  if (!userState || !userState.user) throw new Error("Can't call useUser before the user context is loaded")
  return userState.user
}

export const useOptionalUser = (): User | undefined => {
  const userState = useContext(UserContext)
  return userState?.user
}
