import { AppProps } from 'next/app'
import Head from 'next/head'
import { ErrorBoundary } from '~/components/bugsnag'
import { setupIonicReact } from '@ionic/react'
import UserProvider from '~/components/userProvider'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import '~/styles/globals.css'

setupIonicReact()

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Queso</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="description" content="A future thing" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Head>
      <ErrorBoundary>
        <UserProvider>
          <Component {...pageProps} />
        </UserProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
